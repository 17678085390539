<template>
	<div class="container">
		<img
			class="image"
			:src="require('@/assets/images/other/incoming.png')" />
		<div class="flex flex-column align-center">
			<h1 class="code">MAINTENANCE</h1>
			<div class="message">
				กำลังปิดปรับปรุง ขออภัยในความไม่สะดวก
			</div>
			<a
				class="mt-10"
				target="__blank"
				:href="`https://orderplus.me/product`">
				<el-button
					type="primary"
					plain>ดูรายละเอียด</el-button>
			</a>
		</div>
	</div>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.code {
    font-weight: 500;
	font-size: 82px;
	letter-spacing: 0.03em;
	color: $primary-color-500;
	margin-top: 1rem;
	margin-bottom: 1rem;
	@include respond-to($md) {
		font-size: 40px;
	}
}
.message {
    font-weight: 500;
	font-size: 24px;
	letter-spacing: 0.03em;
	color: #515674;
}
.image {
  width: 26rem;
  max-width: 100%;
  height: auto;
}

.container {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}
</style>